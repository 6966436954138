import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from 'antd';
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = lng === "fa" || lng === "ar" ? "rtl" : "ltr";
    if (lng === "fa"|| lng === "ar") {
      document.body.classList.add("style_rtl");
    } else {
      document.body.classList.remove("style_rtl");
    }
  };
  return (
    <div className="language-switcher">
      <Select
      defaultValue="en"
      value={i18n.language}
      style={{ width: 120 }}
      onChange={changeLanguage}
      options={[
        { value: 'en', label: 'English' },
        { value: 'fa', label: 'فارسی' },
        { value: 'ar', label: 'العربية' },
      ]}
    />
    </div>
    // <div>
    //   <button className="btn btn-custom btn-md" onClick={() => changeLanguage(i18n.language === "fa" ? "en" : "fa")}>EN/FA</button>
    // </div>
  );
};

export default LanguageSwitcher;
